import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import ImperfectlyGood from '../../components/Books/ImperfectlyGood';

export default props => (
  <>
    <SEO
      title="Scrupulosity OCD Book - Titled 'Imperfectly Good' - by Annabella Hagen, LCSW"
      description="In Imperfectly Good, you'll find a step-by-step guide to help you find relief and happiness in spite of religious or moral OCD (scrupulosity OCD). Break free from the doubt and depression caused by moral and religious anxiety."
      pathname="/books/imperfectly-good"
    />
    <Layout>
      <ImperfectlyGood {...props} />
    </Layout>
  </>
);
