import _ from 'lodash';
import React from 'react';
import BookTeaser from '../../BookTeaser';
import PageHeader from '../../PageHeader';
import QuoteImage from '../../QuoteImage';
import data from '../../../data/imperfectlyGood';

import styles from '../books.module.scss';

const ImperfectlyGood = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  const quoteImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1673796720/imperfectly_good_quote_img.jpg';

  const learnList = _.map(data.learn, (skill, index) => {
    return <li key={index}>{skill}</li>;
  });

  const bookPraise = _.map(data.advancePraise, ({ quote, author }) => {
    return (
      <div className={styles.book} key={_.uniqueId()}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: quote }} />
        <br />
        <br />
        <p style={{ textAlign: 'right' }} className={styles.copy}>
          {' '}
          &#8212;<i>{author}</i>
        </p>
      </div>
    );
  });

  return (
    <main>
      <PageHeader
        pageName="Imperfectly Good: A Book About Scrupulosity"
        pageCategory="Books"
        headerImage={background}
      />
      <div className={styles.buyBookWrapperTop}>
        <BookTeaser bookName="Imperfectly Good" shouldLinkToRoute={false} />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.header}>
          Break free from the doubt and depression caused by moral and religious anxiety.
          <br />
          <br />
          There&apos;s help, and there&apos;s hope.
        </h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.headerSmall}>The practices found in this book will help you:</h2>
        <ul>{learnList}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro2 }} />
      </div>

      <QuoteImage image={quoteImage} maxWidth={350} quote={data.imageQuote} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Advance Praise</h2>
        {bookPraise}
      </div>
    </main>
  );
};

export default ImperfectlyGood;
