const imperfectlyGood = {
  imageQuote:
    '<p>"What you care about most counts only when you act&nbsp;on&nbsp;it!."</p><p>ANNABELLA HAGEN</p>',
  intro:
    'In <i>Imperfectly Good</i>, you&apos;ll find a step-by-step guide to help you find relief and happiness in spite of religious or moral OCD (scrupulosity OCD).<br><br>Through sound, research-based principles and practices, as well as real-life accounts of those struggling with scrupulosity OCD, you’ll learn how to progress from a fixed mindset to a growth mindset so you can become who you want to be.',
  learn: [
    'Gain confidence in who you are',
    'Navigate life with mental and emotional flexibility',
    'Better connect with the present despite overwhelming anxiety',
    'Embrace vitality and peace instead of worry and uncertainty',
  ],
  intro2:
    'When you implement these skills, you&apos;ll more easily navigate the turbulent waters of religious or moral OCD and successfully build long-term meaning in your life.<br><br>Don&apos;t suffer needlessly. Open <i>Imperfectly Good</i> and start creating the life you want right now!',
  advancePraise: [
    {
      quote:
        '“Finding a book specific to OCD within the framework of ACT felt like a long-awaited victory. At last! A book for people like me. The book is a delightful read, written in a helpful, concise way that makes a life with OCD seem possible, even hopeful. It felt like a lifeline to me, giving me the desire to keep going and keep finding the joy.”',
      author: 'Brittany H.',
    },
    {
      quote:
        '“<i>Imperfectly Good</i> by Annabella Hagen is a lucid and pragmatic guide for people of any faith tradition or of no faith who, like myself, are learning to manage life with scrupulosity OCD. It is written for those who wish to live the values that “make their heart sing” no matter what they fear or what their rule-based scrupulosity-OCD mind may be telling them. The exercises in this companion manual will help you develop the skills you need to see your internal experiences from a different perspective so you can more truly and flexibly live and find joy in the values that matter most to you.”',
      author: 'Brewster Fay, PhD',
    },
    {
      quote:
        'Highly recommended! Based on principles of ACT, this is an accessible guide to help scrupulous individuals choose a path toward the things they value instead of chasing anxiety relief and ironically finding themselves more stuck in the scrupulosity trap. Along with a therapist, this book can help you develop psychological flexibility and learn to let go of fear and find peace.',
      author: 'Jedidiah Siev, PhD',
    },
    {
      quote:
        'This is honestly one of my new favorite resources for use in the world of faith, OCD, and religious scrupulosity. I deeply appreciate the way Annabella simultaneously helped readers understand their scrupulosity, offer themselves compassion, and move towards their authentic faith in a value-driven way. Many of her metaphors helped to reframe my own understanding of OCD, and I can&apos;t wait to share this groundbreaking resource with the wider community!',
      author: "Rev. Katie O'Dunne, Founder, Faith & Mental Health Integrative Service",
    },
    {
      quote:
        '<i>Imperfectly Good</i> is a fantastic resource for anyone with scrupulosity. It reflects the most up-to-date information and treatment strategies that you need to be guided by faith, not fear!',
      author: 'Jonathan S. Abramowitz, PhD',
    },
    {
      quote:
        'Readers are certain to find tools that will be useful to them in their healing journey.',
      author: 'Debra Theobald McClendon, PhD, Licensed Psychologist',
    },
  ],
};

export default imperfectlyGood;
